export default function countdown () {

  // set all DOM elements
  const countdowns = [...document.querySelectorAll('.js-countdown')]

  // calculate remaining time from now until deadline
  function getRemainingTime (deadline) {
    return deadline - new Date().getTime();
  }


  // pad value with zero
  function pad (value) {
    return (`0${Math.floor(value)}`).slice(-2);
  }

  countdowns.forEach((countdownEl) => {
    const $days = countdownEl.querySelector('.js-countdown-days');
    const $hours = countdownEl.querySelector('.js-countdown-hours');
    const $minutes = countdownEl.querySelector('.js-countdown-minutes');
    const $seconds = countdownEl.querySelector('.js-countdown-seconds');
    const { endDate } = countdownEl.dataset;
    const endTime = Math.round(new Date(endDate).getTime());

    if (getRemainingTime(endTime) < 0) {
      countdownEl.classList.add('has-ended')
    }

    if (!$days) return;

    // show time repeatedly
    function showTime () {
      const remainingTime = getRemainingTime(endTime);
      const seconds = pad((remainingTime / 1000) % 60);
      const minutes = pad((remainingTime / (60 * 1000)) % 60);
      const hours = pad((remainingTime / (60 * 60 * 1000)) % 24);
      const days = pad(remainingTime / (24 * 60 * 60 * 1000));

      $days.innerHTML = days;
      $hours.innerHTML = hours;
      $minutes.innerHTML = minutes;
      $seconds.innerHTML = seconds;

      // ensure clock only updates if a second or more is remaining
      if (remainingTime >= 1000) {
        requestAnimationFrame(showTime);
      }
    }

    // kick it all off
    requestAnimationFrame(showTime);
  })
}

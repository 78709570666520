import './helpers/_detectTouch';
import './modules/_lazy';
import './polyfills/index';
import './libs/jquery.actual';
import svg4everybody from 'svg4everybody';
import AOS from 'aos';
import burgerToggle from './modules/_burger';
import initDropdowns from './modules/_dropdowns';
import Modal from './modules/_modals';
import scrollBar from './modules/_scrollBar';
import scrollToAnchor from './modules/_scrollTo';
import toTop from './modules/_scrollTop';
import toggler from './modules/_simple-toggle';
import initTooltips from './modules/_tooltip';
import menu from './modules/_menu';
import mobMenu from './modules/_mob-menu';
import initMask from './modules/_imask';
import Validation from './modules/_validator';
import countdown from './modules/_countdown';


document.addEventListener('DOMContentLoaded', () => {
  svg4everybody();
  initTooltips();
  menu();
  mobMenu();
  initMask();
  scrollBar();
  toTop();
  initDropdowns();
  burgerToggle();
  scrollToAnchor();
  toggler();

  window.validation = new Validation();
  window.validation.init();

  window.modal = new Modal();
  window.modal.init();


  $(document).on('click', '.js-lnd-default-prod-id', () => {
    $('.js-prod-id-input').val('')
  })
  $(document).on('click', '.js-lnd-product-order', (e) => {
    const prodId = $(e.currentTarget).attr('data-product-id')
    $('.js-prod-id-input').val(prodId)
  })

  countdown()

  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: window.matchMedia('(min-width: 768px)').matches ? 300 : 160, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 600, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

  });
});
